import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = [
    "boards",
    "items",
    "title",
    "input",
    "form",
    "projectId",
    "scopeIcon",
  ];

  connect() {
    // sidebar
    const newBoard = document.querySelector("#addNew > ul > li.new-board");
    if (newBoard) {
      newBoard.remove();
    }

    document.querySelectorAll("#sidebar li.nav-item a").forEach((el) => {
      el.classList.remove("active");
    });
  }

  action(ev) {
    document.body.classList.remove("info-panel-active");

    if (this.inputTarget.value == "") {
      this.boardsTarget.replaceChildren();
      this.itemsTarget.replaceChildren();
      this.titleTarget.innerText = "Search";
    } else {
      if (this.hasTitleTarget) {
        this.titleTarget.innerText = "Search Results";
      }
    }
  }

  submit(ev) {
    gtag("event", "search", {
      term: this.inputTarget.value.trim(),
      scope: this.projectIdTarget.value == "" ? "Everywhere" : "Project",
    });

    return true;
  }

  clear(ev) {
    if (ev.target.value == "") {
      // cleared so handle
      this.formTarget.requestSubmit();
    }
  }

  changeScope(ev) {
    ev.preventDefault();

    this.projectIdTarget.value = ev.params.scope;

    if (ev.params.scope == "") {
      this.scopeIconTarget.classList.remove("fa-folder");
      this.scopeIconTarget.classList.add("fa-globe");
      this.inputTarget.placeholder = "Search Everywhere";
    } else {
      this.scopeIconTarget.classList.add("fa-folder");
      this.scopeIconTarget.classList.remove("fa-globe");
      this.inputTarget.placeholder = "Search This Project";
    }
  }
}
