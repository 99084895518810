import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "boards",
    "items",
    "title",
    "input",
    "form",
    "dropdown",
    "queryPreview",
    "projectId",
  ];

  connect() {
    // sidebar
    const newBoard = document.querySelector("#addNew > ul > li.new-board");
    if (newBoard) {
      newBoard.remove();
    }

    document.querySelectorAll("#sidebar li.nav-item a").forEach((el) => {
      el.classList.remove("active");
    });

    // Close dropdown when clicking outside
    document.addEventListener("click", (e) => {
      if (!this.element.contains(e.target)) {
        this.hideDropdown();
      }
    });
  }

  action(ev) {
    document.body.classList.remove("info-panel-active");

    if (this.inputTarget.value == "") {
      this.boardsTarget.replaceChildren();
      this.itemsTarget.replaceChildren();
      this.titleTarget.innerText = "Search";
    } else {
      this.titleTarget.innerText = "Search Results";
    }
  }

  toggleDropdown(event) {
    const query = this.inputTarget.value.trim();

    if (query) {
      // If it's a click event and dropdown is already shown, don't hide it
      if (
        event.type === "click" &&
        this.dropdownTarget.classList.contains("show")
      ) {
        return;
      }

      this.showDropdown();
      this.queryPreviewTargets.forEach((preview) => {
        preview.textContent = query;
      });
    } else {
      this.hideDropdown();
      // Only submit if this was triggered by an input event (x button click)
      if (event.type === "input") {
        this.projectIdTarget.value = "";
        this.formTarget.requestSubmit();
      }
    }
  }

  showDropdown() {
    this.dropdownTarget.classList.add("show");
  }

  hideDropdown() {
    this.dropdownTarget.classList.remove("show");
  }

  handleKeydown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.projectIdTarget.value = ""; // Search everywhere on enter
      this.recordAndSubmit();
      this.hideDropdown();
    } else if (e.key === "Escape") {
      this.hideDropdown();
    }
  }

  filterProject(ev) {
    this.projectIdTarget.value = ev.currentTarget.value;
    this.formTarget.requestSubmit();
  }

  submitSearch(e) {
    e.preventDefault();
    const scope = e.currentTarget.dataset.searchScope;
    this.projectIdTarget.value = scope;
    this.recordAndSubmit();
    this.hideDropdown();
  }

  recordAndSubmit() {
    gtag("event", "gallery_search", {
      term: this.inputTarget.value.trim(),
      scope: this.projectIdTarget.value == "" ? "Everywhere" : "Project",
    });

    this.formTarget.requestSubmit();
  }
}
