import { Controller } from "@hotwired/stimulus";
import { Sortable } from "sortablejs";
import { headers } from "../utils";

// Connects to data-controller="container"
export default class extends Controller {
  static targets = ["containers", "tiles", "container"];

  connect() {
    Sortable.create(this.containersTarget, {
      forceFallback: true,
      fallbackClass: "sortable-dragging",
      handle: ".btn",
      direction: "vertical",
      scroll: true, // Enables automatic scrolling
      scrollSensitivity: 80, // Distance from the edge of the viewport at which to start scrolling
      scrollSpeed: 10, // Speed of the scroll

      onStart: () => {
        document.body.classList.toggle("sortable-active");

        this.containerTargets.forEach((el) => {
          el.classList.add("sortable-active");
        });
      },

      onEnd: (evt) => {
        document.body.classList.toggle("sortable-active");

        const trigger = new CustomEvent("sortable-complete");
        window.dispatchEvent(trigger);

        this.containerTargets.forEach((el) => {
          el.classList.remove("sortable-active");
        });

        const body = {
          container: {
            position: evt.newIndex,
          },
        };

        Turbo.fetch(evt.item.dataset.containerUrl, {
          method: "PUT",
          headers: headers(),
          body: JSON.stringify(body),
        })
          .then(() => {
            this.reorderDivs(evt.item.dataset.containerId);
          })
          .catch((error) => {
            console.error("error", error);
          });
      },
    });
  }

  reorderDivs(containerId) {
    const containers = document.getElementById("tileContainers");

    // get the new order
    const orderArray = this.containerTargets.map((div) => {
      return div.getAttribute("data-board-container-id-param");
    });

    // Convert array of IDs to array of elements
    const elements = orderArray.map((id) => document.getElementById(id));

    // Remove all elements from their current positions
    // and store them in a document fragment
    const fragment = document.createDocumentFragment();
    elements.forEach((el) => {
      fragment.appendChild(el);
    });

    // Clear the container
    containers.innerHTML = "";

    // Add them back in the correct order
    elements.forEach((el) => {
      containers.appendChild(el);
    });

    // scroll to moved item
    const element = document.getElementById(containerId);
    element.scrollIntoView({
      behavior: "smooth",
    });
  }
}
