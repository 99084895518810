import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "tips"];

  showTips() {
    this.tipsTarget.classList.add("show");
    this.updateTips();
  }

  hideTips(event) {
    if (!this.element.contains(event.relatedTarget)) {
      this.tipsTarget.classList.remove("show");
    }
  }

  updateTips() {
    const input = this.inputTarget.value.toLowerCase();
    const prefixes = ["name:", "source:", "color:", "note:", "board:"];
    const terms = {};
    let nonPrefixTerms = [];

    // Split input preserving quoted strings
    const tokens = [];
    let currentToken = "";
    let inQuotes = false;

    for (let i = 0; i < input.length; i++) {
      const char = input[i];

      if (char === '"') {
        inQuotes = !inQuotes;
        currentToken += char;
      } else if (char === " " && !inQuotes) {
        if (currentToken) {
          tokens.push(currentToken);
          currentToken = "";
        }
      } else {
        currentToken += char;
      }
    }

    if (currentToken) {
      tokens.push(currentToken);
    }

    // Process tokens
    tokens.forEach((token) => {
      const prefix = prefixes.find((p) => token.startsWith(p));
      if (prefix) {
        // Handle quoted values
        let value = token.slice(prefix.length);
        if (value.startsWith('"') && value.endsWith('"')) {
          value = value.slice(1, -1); // Remove quotes
        }
        terms[prefix] = value;
      } else if (token) {
        nonPrefixTerms.push(token);
      }
    });

    this.tipsTarget.innerHTML = `
      <div class="p-2">
        ${
          nonPrefixTerms.length === 0
            ? `
            <div class="mb-2 text-muted">Type anything or use these shortcuts</div>
            <hr>
          `
            : `
            <div class="mb-2 cursor-pointer d-flex justify-content-between align-items-center" data-action="click->search-dropdown#selectTip mousedown->search-dropdown#preventHide">
              <div>
                <i class="fa-solid fa-magnifying-glass me-2"></i>
                <span class="fw-bold">${nonPrefixTerms.join(" ")}</span>
              </div>
              <span class="text-muted ms-3">Enter to search</span>
            </div>
            <hr>
          `
        }
        ${prefixes
          .map((prefix) => {
            if (terms[prefix]) {
              return `
                <div class="mb-2 cursor-pointer d-flex justify-content-between align-items-center" data-action="click->search-dropdown#selectTip mousedown->search-dropdown#preventHide">
                  <div>
                    <code class="text-pink">${prefix}</code>
                    <span class="fw-bold">${terms[prefix]}</span>
                  </div>
                  <span class="text-muted ms-3">Enter to search</span>
                </div>
              `;
            }
            return `
              <div class="mb-2">
                <code class="text-pink">${prefix}</code>
                <span class="text-muted">Search by ${prefix.slice(0, -1)}</span>
              </div>
            `;
          })
          .join("")}
      </div>
    `;
  }

  preventHide(event) {
    event.preventDefault();
  }

  selectTip() {
    this.element.closest("form").requestSubmit();
  }
}
